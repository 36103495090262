/*----FontAwesome-------*/
@import url("../fonts/font-awesome/css/font-awesome.min.css");
// @import "../fonts/font-awesome/css/font-awesome.min.css";
// @import "node_modules/direction-reveal/src/styles/direction-reveal.scss";
@import "bootstrap/dist/css/bootstrap.css";

// Style Scss
@import "main/modal";
@import "main/404";
@import "main/preview";
@import "main/circle";
@import "main/styleswitcher";
@import "main/slick";
@import "main/style";

// skin
@import "main/skins/yellow";
@import "main/skins/blue";
@import "main/skins/blueviolet";
@import "main/skins/goldenrod";
@import "main/skins/green";
@import "main/skins/magenta";
@import "main/skins/orange";
@import "main/skins/purple";
@import "main/skins/red";
@import "main/skins/yellowgreen";
